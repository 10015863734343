// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .seat {
    width: 30px;
    height: 30px;
    margin: 5px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: 0.5s;
  }

  .available:hover {
    background-color: grey;
  }
  
  .available {
    background-color: limegreen;
  }
  
  .selected {
    background-color: #6c7ae0;
  }
  
  .booked {
    background-color: red;
    cursor: not-allowed;
  }

  @media (max-width: 600px) {
    .seat {
        width: 20px;
        height: 20px;
        margin: 3px;
        line-height: 20px;
    }
}

 Media query for very small screens
@media (max-width: 400px) {
    .seat {
        width: 15px;
        height: 15px;
        margin: 2px;
        line-height: 15px;
    }
}
*/`, "",{"version":3,"sources":["webpack://./src/components/Seat.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;CA+CC","sourcesContent":["/* .seat {\n    width: 30px;\n    height: 30px;\n    margin: 5px;\n    display: inline-block;\n    text-align: center;\n    line-height: 30px;\n    border: 1px solid #ccc;\n    cursor: pointer;\n    transition: 0.5s;\n  }\n\n  .available:hover {\n    background-color: grey;\n  }\n  \n  .available {\n    background-color: limegreen;\n  }\n  \n  .selected {\n    background-color: #6c7ae0;\n  }\n  \n  .booked {\n    background-color: red;\n    cursor: not-allowed;\n  }\n\n  @media (max-width: 600px) {\n    .seat {\n        width: 20px;\n        height: 20px;\n        margin: 3px;\n        line-height: 20px;\n    }\n}\n\n Media query for very small screens\n@media (max-width: 400px) {\n    .seat {\n        width: 15px;\n        height: 15px;\n        margin: 2px;\n        line-height: 15px;\n    }\n}\n*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
