import React from 'react';

export default function About() {
    return (
        <div style={{ height: '100vh' }}>
            <iframe
                src="https://test.bonda.tech/"
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="About Page"
            ></iframe>
        </div>
    );
}

/*
Име на постановката: "Пепеляшка"
Име на фондацията: "Деца с онкохематологични заболявания", център "Златна панделка"

Участници в пиесата: 

Владислав Илиев
Ронислава Николова
Татяна Хаджииванова
Цветелина Радева
Лора Серафимова
Мила Бончева
Валерия Топалова
Стефан Енев 
Патрик Хесън
Крум Кадрев
Яница Тончева
Вера Ергина
Симона Лазарова

костюмографи:
Стилиан Неделчев
Анна Костадинова
Деница Христова
Жана Стоянова

режисьор:
Владимир Стойчев

ден и час: 17.06.2024г. 19:00
*/