// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
    width: 100%;
    background-color: rgb(35, 82, 158);
}

nav ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style: none;
    padding: 0;
    margin: 0;
}

nav ul li {
    position: relative;
}

nav ul li a {
    text-decoration: none;
    color: white;
    transition: color 0.5s;
}

nav ul li:hover a {
    color: gray;
}

main {
    background-image: url('https://www.google.com/url?sa=i&url=https%3A%2F%2Fgrabo.bg%2Fsofia%2Fna-teatar-0qwww8&psig=AOvVaw166b1lkWgzLlaWfj3oKTv-&ust=1717065015541000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCOCf7-bTsoYDFQAAAAAdAAAAABAE');
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kCAAkC;AACtC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,gBAAgB;IAChB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qPAAqP;AACzP","sourcesContent":["nav {\n    width: 100%;\n    background-color: rgb(35, 82, 158);\n}\n\nnav ul {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-evenly;\n    list-style: none;\n    padding: 0;\n    margin: 0;\n}\n\nnav ul li {\n    position: relative;\n}\n\nnav ul li a {\n    text-decoration: none;\n    color: white;\n    transition: color 0.5s;\n}\n\nnav ul li:hover a {\n    color: gray;\n}\n\nmain {\n    background-image: url('https://www.google.com/url?sa=i&url=https%3A%2F%2Fgrabo.bg%2Fsofia%2Fna-teatar-0qwww8&psig=AOvVaw166b1lkWgzLlaWfj3oKTv-&ust=1717065015541000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCOCf7-bTsoYDFQAAAAAdAAAAABAE');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
