// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
main {
    /* margin-left: 20em; */
}

#ticket-purchase-container {
    background-color: rgb(35, 82, 158);
}`, "",{"version":3,"sources":["webpack://./src/components/Home.css"],"names":[],"mappings":";AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":["\nmain {\n    /* margin-left: 20em; */\n}\n\n#ticket-purchase-container {\n    background-color: rgb(35, 82, 158);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
