import React from 'react';

export default function Footer() {

    return (
        <footer>
            <h3>2024 Националната природо-математическа гимназия „Академик Любомир Чакалов“</h3>
            <h3>bonda.tech &#169; All rights reserved</h3>
        </footer>
    );
}