// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.homeSection {
    border-radius: 1em;
    text-decoration: none;
}

.homeSection * {
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/HomeContainer.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":["\n.homeSection {\n    border-radius: 1em;\n    text-decoration: none;\n}\n\n.homeSection * {\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
