// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `    .Purchase {
    text-align: center;
    margin: 20px;
  }
  
  .summary {
    margin-top: 20px;
  }
  
  .purchase-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: rgb(35, 82, 158);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 1em;
    transition: 0.5s;
  }

  .purchase-button:hover {
    background-color: grey;
  }`, "",{"version":3,"sources":["webpack://./src/components/Purchase.css"],"names":[],"mappings":"IAAI;IACA,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,kCAAkC;IAClC,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":["    .Purchase {\n    text-align: center;\n    margin: 20px;\n  }\n  \n  .summary {\n    margin-top: 20px;\n  }\n  \n  .purchase-button {\n    margin-top: 10px;\n    padding: 10px 20px;\n    background-color: rgb(35, 82, 158);\n    color: white;\n    border: none;\n    cursor: pointer;\n    border-radius: 1em;\n    transition: 0.5s;\n  }\n\n  .purchase-button:hover {\n    background-color: grey;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
